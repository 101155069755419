<template>
  <div class="privacyPolicy">
    <!-- 隐私政策 AGT009 -->
    <div class="content">
      <div class="title center bold">隐私权条款</div>
      <div class="mt3 indent5">{{companyName}}（以下亦称“我们”或“村口科技”）尊重并保护所有使用我们平台服务的用户的个人隐私权，在您使用我们提供的服务时（下称“服务”），我们将按照本隐私权条款收集、使用、共享及保护您的个人信息。本隐私权条款包含了我们收集、存储、使用、共享和保护您的个人信息的条款，我们希望通过本隐私权条款向您清晰地介绍我们对为您提供的访问、更新、控制和保护您个人信息的方式，因此请您完整地阅读本《隐私政策》（下称“本隐私政策”），作出您认为适当的选择。如果您不同意本隐私政策的任何内容，您应立即停止使用我们平台服务。当您使用我们平台提供的任一服务时，即表示您已同意我们按照本隐私政策来合法使用和保护您的个人信息。</div>

      <div class="bold mt3 indent5">一、定义</div>
      <div class="indent5">1、我们平台，是指包含但不限于{{companyName}}运营之{{companyName}}网站、及{{companyName}}移动应用软件（APP）、H5。</div>
      <div class="indent5">2、关联方：指{{companyName}}存在关联关系的公司的单称或合称。</div>

      <div class="bold mt3 indent5">二、适用范围</div>
      <div class="indent5">为用户提供更好、更优、更个性化的服务是我们坚持不懈的追求，也希望通过我们提供的服务可以使您的生活更方便。本隐私权条款适用于我们提供的所有服务，您访问村口科技平台网站或登陆相关客户端使用村口科技平台提供的服务，均适用本隐私权条款。</div>


      <div class="bold mt3 indent5">三、信息收集</div>
      <div class="indent5">我们提供服务时，可能会收集、储存和使用下列与您有关的信息。如果您不提供相关信息，可能无法注册成为我们的用户或无法享受我们提供的某些服务，或者无法达到相关服务拟达到的效果。</div>
      <div class="indent5">1、您向我们提供的信息。</div>
      <div class="indent5">当您在村口平台注册账户时填写或使用我们提供的服务时提交的信息，包括但不限于您的电话号码、用户名信息、个人信息等信息。</div>
      <div class="indent5">2、在您使用服务过程中收集的信息。</div>
      <div class="indent5">为了提供并优化您需要的服务，我们会收集您使用服务的相关信息，这类信息包括:</div>
      <div class="indent5">1）在您使用我们平台服务，或访问我们平台网页时，我们自动接收并记录的您的浏览器和计算机、移动终端上的信息，包括但不限于您的IP地址、实际所在位置的信息、浏览器的类型、使用的语言、访问日期和时间、软硬件特征信息及您需求的网页记录等数据；如您下载或使用村口科技或其关联公司客户端软件，或访问移动网页使用我们平台服务时，我们可能会读取与您位置和移动设备相关的信息，包括但不限于设备型号、设备识别码、操作系统、分辨率、电信运营商等。</div>
      <div class="indent5">2）在使用村口平台服务，或访问我们平台网页时，请您不要主动向村口科技提供您的个人敏感信息（如种族、宗教、政治立场、个人健康和医疗信息、性取向等），否则我们不保证被意外收集的可能性。</div>
      <div class="indent5">3）在您使用我们平台提供的专项服务时，我们会依据专项服务的要求收集您的其他必要信息。我们将严格按照法律法规的要求进行信息的收集。您应确保您填写或提交的身份信息的真实性，否则我们可选择拒绝为您提供相关的服务。</div>
      <div class="indent5">4）为了地图导航和其他位置服务相关目的，我们的产品中会嵌入合作方的SDK或其他类似的应用程序，例如百度SDK需要您打开设备位置权限并收集设备所在位置相关信息、设备识别号、联网相关信息。</div>
      <div class="indent5">5）除上述信息外，我们还可能为了提供服务及改进服务质量的合理需要而收集您的其他信息，包括您与我们的客户服务团队联系时所提供的相关信息，您参与问卷调查时向我们发送的问卷答复信息，以及您与我们的关联方、我们合作伙伴之间互动时我们收集的相关信息。与此同时，为提高您使用我们平台提供的服务的安全性，更准确地预防钓鱼网站欺诈和木马病毒，我们可能会通过了解一些您的网络使用习惯、您常用的软件信息等手段来判断您账户的风险，并可能会记录一些我们认为有风险的URL。</div>
      <div class="indent5">3、来自第三方的信息</div>
      <div class="indent5">为了给您提供更好、更优、更加个性化的服务，或共同为您提供服务，或为了预防互联网欺诈的目的，我们的关联方、合作伙伴会依据法律的规定或与您的约定或征得您同意的前提下，向我们分享您的个人信息。</div>
      <div class="indent5">您了解并同意，以下信息不适用本隐私权条款：</div>
      <div class="indent5">1）您在使用我们平台提供的站内搜索服务时输入的关键字信息；</div>
      <div class="indent5">2）信用评价、违反法律规定或违反村口科技规则行为及村口科技已对您采取的措施；</div>
      <div class="indent5">3）应法律法规要求需公示的企业名称等相关工商注册信息以及自然人经营者的信息。</div>


      <div class="bold mt3 indent5">四、信息使用的目的和方式</div>
      <div class="indent5">因收集您的信息是为了向您提供服务及提升服务质量的目的，为了实现这一目的，我们会把您的信息用于下列用途：</div>
      <div class="indent5">1、向您提供您使用的各项服务，并维护、改进这些服务。</div>
      <div class="indent5">2、我们可能使用您提供的个人信息中的联系方式与您通信，例如通知您有关您的账户、安全性更新、产品和服务信息。为了更好的为您提供服务，请您及时查看我们发送的相关信息。</div>
      <div class="indent5">3、向您推荐您可能感兴趣的内容，包括但不限于通过系统向您展示个性化的第三方推广信息，或者根据本隐私权条款与我们的合作伙伴共享信息以便他们向您发送有关其产品和服务的信息。如果您对我们推荐的内容不感兴趣，请及时回复取消内容的通知。</div>
      <div class="indent5">4、我们可能使用您的个人信息以预防、发现、调查欺诈、危害安全、非法或违反与我们或其关联方协议、政策或规则的行为，以保护您、其他用户，或我们或其关联方的合法权益。</div>
      <div class="indent5">5、我们可能会将来自某项服务的个人信息与来自其他服务的信息结合起来，用于为了给您提供更加个性化的服务使用，例如让您拥有更广泛的社交圈的需要而使用、共享或披露。</div>
      <div class="indent5">6、我们不会向任何无关第三方提供、出售、出租、分享或交易您的个人信息，除非事先得到您的同意，或该第三方和村口科技及其关联方单独或共同为您提供服务，且在该服务结束后，其将被禁止访问包括其以前能够访问的所有这些资料。</div>
      <div class="indent5">7、我们的产品集成友盟SDK，友盟SDK需要收集您的设备信息（IMEI/MAC/Android ID/IDFA/OpenUDID/GUID/SIM卡IMSI/地理位置等）以提供友盟统计和消息分享功能，相关政策协议请参见<a href="https://www.umeng.com/page/policy">【友盟隐私政策】</a>。</div>


      <div class="bold mt3 indent5">五、信息共享</div>
      <div class="indent5">我们对您的信息承担保密义务，我们仅会在下列情况下才将您的信息与第三方共享：</div>
      <div class="indent5">1、事先获得您或您的监护人的同意或授权；</div>
      <div class="indent5">2、为免除您在生命、身体或财产方面的紧急危险；</div>
      <div class="indent5">3、根据法律法规的规定或行政、司法机构的要求；</div>
      <div class="indent5">4、向村口科技的关联方分享您的信息；</div>
      <div class="indent5">5、向合作伙伴提供您的信息，前提是村口科技旗下相应主体已与该第三方签署了适当的保密条款；</div>
      <div class="indent5">6、以学术研究或公共利益为目的，且无害于您的重大利益；</div>
      <div class="indent5">7、如您是适格的知识产权投诉人并已提起投诉，应被投诉人要求，向被投诉人披露，以便双方处理可能的权利纠纷；</div>
      <div class="indent5">8、只有共享您的信息，才能提供您需要的服务，或处理您与他人的纠纷或争议。</div>
      <div class="indent5">9、如您出现违反中国有关法律、法规或者村口科技相关协议或相关规则的情况，需要向第三方披露；</div>
      <div class="indent5">10、为维护村口科技及其关联方或用户的合法权益；</div>
      <div class="indent5">11、为完成合并、分立、收购或资产转让；</div>
      <div class="indent5">12、您通过点击相关产品的相应按键同意向第三方提交个人信息；</div>
      <div class="indent5">13、为更好地向您提供服务所需的其他情形。</div>
      <div class="indent5">因此，请您谨慎考虑通过我们的服务上传、发布和交流的信息内容。在一些情况下，您可通过我们某些服务的隐私设定来控制有权浏览您共享信息的用户范围。如要求从我们的服务中删除您的相关信息，请通过该等特别服务条款提供的方式操作。</div>


      <div class="bold mt3 indent5">六、Cookie和网络Beacon的使用</div>
      <div class="indent5">为使您获得更轻松的访问体验，您访问我们平台相关网站或使用我们平台提供的服务时，我们可能会通过小型数据文件识别您的身份，这么做是帮您省去重复输入注册信息的步骤，或者帮助判断您的账户安全。这些数据文件可能是Cookie，Flash Cookie，或您的浏览器或关联应用程序提供的其他本地存储（统称“Cookie”）。</div>
      <div class="indent5">请您理解，我们的某些服务只能通过使用“Cookie”才可得到实现。如果您的浏览器或浏览器附加服务允许，您可以在相关的页面修改对Cookie的接受程度或者拒绝村口科技的Cookie，但这一举动在某些情况下可能会影响您安全访问村口科技相关网站和使用村口科技平台提供的服务。</div>
      <div class="indent5">网页上常会包含一些电子图象（称为“单像素” GIF 文件或 “网络 beacon”），使用网络beacon可以帮助网站计算浏览网页的用户或访问某些cookie，我们会通过网络beacon收集您浏览网页活动的信息，例如您访问的页面地址、您先前访问的援引页面的位址、您停留在页面的时间、您的浏览环境以及显示设定等。</div>


      <div class="bold mt3 indent5">七、信息存储</div>
      <div class="indent5">村口科技收集的有关您的信息和资料将保存在村口科技及（或）其关联公司的服务器上，这些信息和资料可能传送至您所在国家、地区或村口科技收集信息和资料所在地并在该地被访问、存储和展示。</div>



      <div class="bold mt3 indent5">八、您的个人信息的查询及变更</div>
      <div class="indent5">1、无论您何时使用我们的服务，我们都会力求让您顺利访问自己的个人信息。如果这些信息有误，我们会努力提供各种方式来让您快速更新信息或删除信息（除非我们出于合法业务或法律方面的原因而必须保留这些信息）。您可以自行登录您的账户对您的个人信息进行查询及更新。如果您认为村口科技存在违反法律、行政法规的规定或者与您的约定收集、使用您个人信息的，您可以通过客服电话或者通过村口科技网站的帮助中心页面与我们取得联系。更新您的个人信息时，我们可能会先要求您验证自己的身份，然后再处理您的请求。对于那些无端重复、需要过多技术手段（例如需要开发新系统或从根本上改变现行惯例）、给他人隐私权带来风险或者非常不切实际（例如涉及备份磁带上存放的信息）的请求，我们可能会予以拒绝。</div>
      <div class="indent5">2、只要我们能够让您访问和修改信息，而且其不需要过多投入，则我们会免费提供。我们力求对服务进行完善的维护，以保护信息免遭意外或恶意的破坏。因此，当您从我们的服务中删除信息后，我们可能不会立即从在用的服务器中删除这些信息的残留副本，也可能不会从备份系统中删除相应的信息。</div>



      <div class="bold mt3 indent5">九、您的个人信息保护</div>
      <div class="indent5">1、我们仅在本隐私条款所述目的所必需的期间和法律法规要求的时限内保留您的个人信息。为保障您的信息安全，我们努力采取各种合理的物理、电子和管理方面的安全措施来保护您的信息，使您的信息不会被泄漏、毁损或者丢失，包括但不限于SSL、信息加密存储、数据中心的访问控制。我们对可能接触到您的信息的员工或外包人员也采取了严格管理，包括但不限于根据岗位的不同采取不同的权限控制，与他们签署保密协议，监控他们的操作情况等措施。村口科技会按现有技术提供相应的安全措施来保护您的信息，提供合理的安全保障，我们将尽力做到使您的信息不被泄漏、毁损或丢失。</div>
      <div class="indent5">2、您的账户均有安全保护功能，请妥善保管您的账户及密码信息。我们将通过向其它服务器备份、对用户密码进行加密等安全措施确保您的信息不丢失，不被滥用和变造。尽管有前述安全措施，但同时也请您理解，由于技术的限制以及可能存在的各种恶意手段，即便竭尽所能加强安全措施，在信息网络上也不存在“完善的安全措施”。如因您自己的原因导致账户及密码信息泄露而造成的任何法律后果需由您本人负责。</div>
      <div class="indent5">3、在使用村口科技平台服务进行网上交易时，如您不可避免地要向交易对方或潜在的交易对方披露自己的个人信息（例如联系人、联络方式等），请您妥善保护自己的个人信息，仅在必要的情形下向他人提供。如您发现自己的个人信息已经被泄露或者存在被泄露的可能，且有可能会危及您注册获得的”村口壹站”账户安全，或者给您造成其他的损失的，请您务必在第一时间通知客服，以便采取相应措施维护您的”村口壹站”账户安全，防止损失的发生或者进一步扩大。为避免疑义，如果您未在第一时间通知客服，则您应自行承担由此给您造成的损失（及扩大的损失）。</div>



      <div class="bold mt3 indent5">十、未成年人保护</div>
      <div class="indent5">未满十八岁的未成年人应在其父母或监护人指导下使用我们的服务。我们建议未成年人鼓励他们的父母或监护人阅读本隐私权条款并建议未成年人在提交个人信息之前寻求父母或监护人的同意和指导，未成年人一旦使用本服务，我们视为有未成年人父母或监护人的同意或指导。</div>



      <div class="bold mt3 indent5">十一、其他</div>
      <div class="indent5">1、如您对本隐私权条款有任何疑问或您提供的上述个人信息受到了侵扰，您可以通过客服电话与我们联系，我们将在接到投诉后尽快给予您答复。</div>
      <div class="indent5">2、村口科技有权对本隐私权条款进行更新，以反映我们的信息操作的变更。如果我们对其作了任何重大变更，我们会在该等变更生效之前，通过站内信或在网站上挂出通知的方式通知您，您仍继续使用村口科技提供的服务，即表示您同意受经修订本隐私权条款的约束。我们鼓励您定期地查阅本网页上关于我们隐私实践的最新信息。</div>
      <div class="indent5">3、我们将致力于保障您个人信息的私密性和安全性，并让您能够自主控制。本隐私权条款属于《平台注册服务协议》不可分割的有效组成部分。</div>



    </div>
  </div>
</template>
<script>
  import {COMPANY_NAME} from '@/utils/constantNum'
  import {uwStatisticAction} from "../../utils/util";
  export default {
    name:'privacyPolicy',
    data() {
      return {
        companyName:COMPANY_NAME,
        h5Name:'村口壹站'
      }
    },
    mounted() {
      uwStatisticAction('/agreement/privacyPolicy','隐私权条款')
    }
  }
</script>
<style lang="less" scoped>
.privacyPolicy{
  background: #f5f5f5;
  font-size: 0.3rem;
  box-sizing: border-box;
  padding: 0.5rem 0.3rem 1rem;
  -webkit-overflow-scrolling: touch;
  .content{
    .title{
      font-size: 0.4rem;
    }
    .bold{
      font-weight: bold
    }
    .center{
      text-align: center;
    }
    .indent5{
      text-indent: 0.6rem;
    }
    .mt3{
      margin-top: 0.3rem;
    }
    .underline{
      text-decoration: underline;
    }
  }
  .footer{
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    .van-button{
      width: 100%;
    }
  }
}
</style>
